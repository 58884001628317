<template>
  <b-card-code title="" style="margin-top: 30px">
    <b-row>
      <b-col md="2">
        <button style="margin-top: -60px; float: right" class="btn btn-primary" @click="AddRow">إضافة داتا</button>
      </b-col>
      <b-col md="2">
        <button style="margin-top: -60px; float: left" class="btn btn-primary" @click="DownloadData">تحميل الداتا</button>
      </b-col>
    </b-row>
    <div class="container-fluid pt-3">
      <div class="row">
        <div class="col-md-3 mb-1">
          <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" :text="searchColumn == null ? 'الخانه' : searchColumn" variant="outline-primary">
            <b-dropdown-item
              v-for="column in columns"
              :key="column.field"
              :value="column.field"
              @click=";(selectedFilterColumn = column.field), (searchColumn = column.label)"
            >
              {{ column.label }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="col-md-3 mb-1">
          <b-input-group>
            <b-form-input v-model="filterValue" @keyup.enter="handleFilter" placeholder="القيمه"></b-form-input>
            <b-input-group-append>
              <b-button @click="handleFilter" variant="outline-secondary">
                <feather-icon icon="SearchIcon"></feather-icon>
              </b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button @click=";(searchColumn = null), (filterValue = null), GetRowData()" variant="outline-secondary">
                <feather-icon icon="XIcon"></feather-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <!-- الاعدادات -->
        <div class="col-md-3 mb-1">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="fullReportColumns">
            <feather-icon icon="SettingsIcon" size="18" />
          </b-button>
        </div>
      </div>
    </div>

    <!-- table -->
    <vue-good-table
      :key="tableRenderKey"
      :columns="columns"
      :rows="reportData"
      :rtl="direction"
      :lineNumbers="true"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'Release'">
          <button style="" class="btn btn-success" v-b-tooltip.hover.right="'تم الإفراج عن الشحنه'" @click="$bvModal.show('release' + props.row.Id.toString())">
            <feather-icon icon="EditIcon" size="12" />
          </button>
          <b-modal :id="'release' + props.row.Id.toString()" centered header="test" header-class="justify-content-center" title="تأكيد الإفراج" hide-footer>
            <div class="col-12 text-center">
              <b-form-group label="تاريخ الإفراج عن الشحنه" label-for="v-releaseDate" style="font-size: 20px">
                <b-form-input id="v-releaseDate" type="date" v-model="releaseDate" />
              </b-form-group>

              <b-button variant="success" size="sm" class="mt-2 mr-2" @click="ReleaseDone(props.row.Id) + $bvModal.hide('release' + props.row.Id.toString())"
                >تأكيد</b-button
              >
              <b-button variant="primary" size="sm" class="mt-2 ml-2" @click="$bvModal.hide('release' + props.row.Id.toString())">إلغاء</b-button>
            </div>
          </b-modal>
        </span>
        <span v-else-if="props.column.field === 'Action'">
          <div class="d-flex justify-content-between">
            <button style="" class="btn btn-primary mr-1" v-b-tooltip.hover.right="'تعديل'" @click="EditRow(props.row.Id)">
              <feather-icon icon="EditIcon" size="12" />
            </button>
            <button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.right="'حذف'"
              class="btn btn-danger"
              @click="$bvModal.show(props.row.Id.toString())"
            >
              <feather-icon icon="Trash2Icon" size="12" />
            </button>
          </div>

          <b-modal :id="props.row.Id.toString()" centered header="test" header-class="justify-content-center" title="تأكيد الحذف" hide-footer>
            <div class="col-12 text-center">
              <p>
                <strong>هل انت متأكد من الحذف ؟ </strong>
              </p>
              <b-button variant="primary" size="sm" class="mt-2 mr-2" @click="DeleteRow(props.row.Id) + $bvModal.hide(props.row.Id.toString())">تأكيد</b-button>
              <b-button variant="danger" size="sm" class="mt-2 ml-2" @click="$bvModal.hide(props.row.Id.toString())">إلغاء</b-button>
            </div>
          </b-modal>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> عرض الصفحة الأولى </span>

            <b-form-select v-model="pageLength" :options="['3', '7', '10', '15', '20']" class="mx-1" @change="onPerPageChange" />
            <span class="text-nowrap"> من {{ totalCount }} العناصر </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              class="mt-1 mb-0"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import * as XLSX from 'xlsx'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  VBTooltip,
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BInputGroupAppend,
  BInputGroup,
  BIcon
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { codeBasic } from '../code'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    VBTooltip,
    BButton,
    BModal,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BInputGroupAppend,
    BInputGroup,
    BIcon
  },

  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple
  },
  created() {
    this.GetReportItems()
    this.GetRowData()
  },

  data() {
    return {
      selectedFilterColumn: null, // Tracks the currently selected column for filtering
      filterValue: null, // The global filter value
      searchColumn: null,
      admins: [],
      pageLength: 7,
      currentPage: 1,
      totalCount: 0, // Total rows/items count from API
      totalPages: 0, // Total pages count from API
      dir: false,
      codeBasic,
      reportItems: [],
      columns: [],
      reportData: [],
      searchTerm: '',
      releaseDate: null,
      dataExported: [],
      tableRenderKey: 0
    }
  },
  methods: {
    fullReportColumns() {
      this.$router.push('full-report-columns')
    },
    async handleFilter() {
      // Example: Fetch filtered data from your API
      await this.GetRowData(this.selectedFilterColumn, this.filterValue)
    },
    async GetReportItems() {
      try {
        let reportId = {
          label: 'رقم المسلسل',
          field: 'Id'
        }
        var response = await this.$store.dispatch('GetFullReportColumnsGrid')
        if ((response.status = 200)) {
          this.columns = response.data
          let actionObj = {
            label: 'التفاصيل',
            field: 'Action'
          }
          this.columns.push(actionObj)
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async onPerPageChange(newPerPage) {
      // Update pageLength and refresh data for the current page
      this.pageLength = parseInt(newPerPage, 10)
      await this.GetRowData(this.selectedFilterColumn, this.filterValue)
    },
    async handlePageChange() {
      // Fetch data for the current page
      await this.GetRowData(this.selectedFilterColumn, this.filterValue)
    },
    async GetRowData(filterKey, filterValue) {
      if (filterKey != null || filterValue != null) {
        this.SearchData(filterKey, filterValue)
      } else {
        const payload = {
          page: this.currentPage,
          pageSize: this.pageLength
        }
        try {
          const response = await this.$store.dispatch('GetFullReportData', payload)

          if (response.status === 200) {
            this.reportData = response.data.result
            this.tableRenderKey++
            this.totalCount = response.data.totalCountData
            this.totalPages = response.data.pagesCount
          }
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger'
            }
          })
        }
      }
    },
    async SearchData(filterKey, filterValue) {
      const payload = {
        page: this.currentPage,
        pageSize: this.pageLength,
        filterKey: filterKey,
        filterValue: filterValue,
        isReleased: false
      }
      try {
        const response = await this.$store.dispatch('SearchFullReportData', payload)

        if ((response.status = 200)) {
          this.reportData = response.data.result
          this.totalCount = response.data.totalCountData
          this.totalPages = response.data.pagesCount
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    AddRow() {
      this.$router.push('add-row')
    },
    EditRow(id) {
      this.$router.push({
        name: 'add-row',
        params: { id: id }
      })
    },
    async DeleteRow(id) {
      try {
        const response = await this.$store.dispatch('DeleteRow', id)

        if ((response.status = 200)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم الحذف بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
          this.reportData = this.reportData.filter((i) => i.Id !== id)
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },

    async ReleaseDone(id) {
      const payload = {
        id: id,
        releaseDate: this.releaseDate
      }
      try {
        const response = await this.$store.dispatch('UpdateShipmentStatus', payload)

        if ((response.status = 200)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم تخريج الشحنه بنجاح',
              icon: 'CheckIcon',
              variant: 'success'
            }
          })

          this.reportData = this.reportData.filter((i) => i.Id !== id)
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    async DownloadData() {
      // Inside an async function
      await this.ExportData()
      const that = this
      that.jsonData = that.dataExported.map((item) => {
        let newItem = {}
        // Assuming that.columns is an array of objects with a 'label' and 'field' properties
        that.columns.forEach((column) => {
          // Use the column.field to get the value from item
          // Use the column.label as the new key in newItem
          newItem[column.label] = item[column.field]
        })
        return newItem
      })

      const data = XLSX.utils.json_to_sheet(that.jsonData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'التقرير-المجمع.xlsx')
    },

    async ExportData() {
      try {
        const data = await this.$store.dispatch('ExportReport', false)
        if (data.statusCode === 200) {
          this.dataExported = data.result
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  }
}
</script>

<style>
.dropdown-menu.show {
  max-height: 250px;
  overflow-y: scroll;
}
</style>
<style scoped>
.vgt-left-align,
.sortable {
  max-width: 120px;
}
</style>
